<template>
  <v-ons-page>
    <v-ons-toolbar style="background: #E59866;">
      <div class="left">
        <v-ons-toolbar-button style="color: white" @click="goBack()"
          ><i class="fa fa-arrow-left"></i
        ></v-ons-toolbar-button>
      </div>
      <div class="right" style="color: white">
        <v-ons-toolbar-button style="color: white" :disabled='!frmDirty' @click="handleSubmit()"
          ><i class="fa fa-save"></i>
        </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>
    <v-ons-card>
      <div class="content">
        <form
          @submit.prevent="handleSubmit"
          @change="handleChange"
          @invalid.capture.prevent="handleInvalid"
        >
          <v-ons-list-item>
            <v-ons-row>
              <!-- <label class="label">Username</label> -->
              <v-ons-col width="50%">
                <div>
                  <v-ons-input
                    :class="errorClass('firstname1')"
                    type="text"
                    name="firstname1"
                    placeholder="First Name 1"
                    float
                    v-model="form.FirstName1"
                  />
                </div>
                <p v-if="fieldErrors.firstname1" style="color:red">
                  {{ fieldErrors.firstname1 }}
                </p>
              </v-ons-col>
              <v-ons-col width="50%">
                <div>
                  <v-ons-input
                    :class="errorClass('lastname1 ')"
                    type="text"
                    name="lastname1"
                    placeholder="Last Name 1"
                    float
                    v-model="form.LastName1"
                  />
                </div>
                <p v-if="fieldErrors.lastname1" style="color:red">
                  {{ fieldErrors.lastname1 }}
                </p>
              </v-ons-col>
            </v-ons-row>
          </v-ons-list-item>
          <v-ons-list-item>
            <v-ons-row>
              <!-- <label class="label">Username</label> -->
              <v-ons-col width="50%">
                <div>
                  <v-ons-input
                    :class="errorClass('firstname2')"
                    type="text"
                    name="firstname2"
                    placeholder="First Name 2"
                    float
                    v-model="form.FirstName2"
                  />
                </div>
                <p v-if="fieldErrors.firstname2" style="color:red">
                  {{ fieldErrors.firstname2 }}
                </p>
              </v-ons-col>
              <v-ons-col width="50%">
                <div>
                  <v-ons-input
                    :class="errorClass('lastname2 ')"
                    type="text"
                    name="lastname2"
                    placeholder="Last Name 2"
                    float
                    v-model="form.LastName2"
                  />
                </div>
                <p v-if="fieldErrors.lastname2" style="color:red">
                  {{ fieldErrors.lastname2 }}
                </p>
              </v-ons-col>
            </v-ons-row>
          </v-ons-list-item>
          <v-ons-list-item>
            <!-- <label class="label">Email</label> -->
            <div class="center">
              <v-ons-input
                style="width:100%;display:inline-block"
                :class="errorClass('company')"
                type="text"
                name="company"
                placeholder="Company"
                float
                v-model="form.Company"
              />
            </div>
            <p v-if="fieldErrors.company" style="color:red">
              {{ fieldErrors.company }}
            </p>
          </v-ons-list-item>
          <v-ons-list-item>
            <v-ons-row>
              <div>
                <v-ons-input
                  :class="errorClass('worktel')"
                  class="editrow"
                  placeholder="Work Tel"
                  name="worktel"
                  float
                  type="tel"
                  v-model="form.ContactNo"
                />
              </div>

              <p v-if="fieldErrors.worktel" style="color:red">
                {{ fieldErrors.worktel }}
              </p>
            </v-ons-row>
          </v-ons-list-item>
          <v-ons-list-item>
            <v-ons-row>
              <div>
                <v-ons-input
                  class="editrow"
                  :class="errorClass('mobiletel')"
                  placeholder="Mobile No"
                  name="mobiletel"
                  float
                  type="tel"
                  v-model="form.MobileTel"
                />
              </div>
              <p v-if="fieldErrors.mobiletel" style="color:red">
                {{ fieldErrors.mobiletel }}
              </p>
            </v-ons-row>
          </v-ons-list-item>
          <v-ons-list-item>
            <v-ons-row>
              <div>
                <v-ons-input
                  class="editrow"
                  :class="errorClass('hometel')"
                  placeholder="Home Tel"
                  name="hometel"
                  float
                  type="tel"
                  v-model="form.HomeTel"
                />
              </div>
              <p v-if="fieldErrors.hometel" style="color:red">
                {{ fieldErrors.hometel }}
              </p>
            </v-ons-row>
          </v-ons-list-item>
          <v-ons-list-item>
            <!-- <label class="label">Email</label> -->
            <div class="center">
              <v-ons-input
                style="width:100%;display:inline-block"
                :class="errorClass('email1')"
                type="email"
                name="email1"
                placeholder="Email 1"
                float
                v-model="form.Email"
              />
            
            <p v-if="fieldErrors.email1" style="color:red">
              {{ fieldErrors.email1 }}
            </p>
            </div>
          </v-ons-list-item>
          <v-ons-list-item>
            <!-- <label class="label">Email</label> -->
            <div class="center">
              <v-ons-input
                style="width:100%;display:inline-block"
                :class="errorClass('email2')"
                type="email"
                name="email2"
                placeholder="Email 2"
                float
                v-model="form.Email_key3"
              />
            
            <p v-if="fieldErrors.email2" style="color:red">
              {{ fieldErrors.email2 }}
            </p>
            </div>
          </v-ons-list-item>
          <v-ons-list-item>
            <!-- <label class="label">Email</label> -->
            <div class="center">
              <v-ons-input
                style="width:100%;display:inline-block"
                :class="errorClass('address1')"
                type="text"
                name="address1"
                placeholder="Address 1"
                float
                v-model="form.Street1"
              />
            </div>
            <p v-if="fieldErrors.address1" style="color:red">
              {{ fieldErrors.address1 }}
            </p>
          </v-ons-list-item>
          <v-ons-list-item>
            <!-- <label class="label">Email</label> -->
            <div class="center">
              <v-ons-input
                style="width:100%;display:inline-block"
                :class="errorClass('address2')"
                type="text"
                name="address2"
                placeholder="Address 2"
                float
                v-model="form.address2"
              />
            </div>
            <p v-if="fieldErrors.address2" style="color:red">
              {{ fieldErrors.Street2 }}
            </p>
          </v-ons-list-item>
          <v-ons-list-item>
            <v-ons-input
              :class="errorClass('city')"
              name="city"
              type="text"
              placeholder="City"
              style="width:50%"
              float
              v-model="form.City"
            />
            <v-ons-input
              :class="errorClass('zip')"
              name="zip"
              type="text"
              placeholder="Postal Code"
              style="width:50%"
              float
              v-model="form.Zip"
            />
          </v-ons-list-item>
          <v-ons-list-item class="editrow">
            <v-ons-input
              :class="errorClass('province')"
              name="province"
              type="text"
              placeholder="Province"
              style="width:50%"
              float
              v-model="form.State"
            />
            <v-ons-input
              :class="errorClass('country')"
              name="country"
              type="text"
              placeholder="Country"
              style="width:50%"
              float
              v-model="form.Country"
            />
          </v-ons-list-item>
          <v-ons-list>
            <button class="button" :disabled='!frmDirty' >Update</button>
          </v-ons-list>
        </form>
      </div>
    </v-ons-card>
  </v-ons-page>
</template>

<script>
import axios from 'axios'
export default {
  name: "editowner2",
  data() {
    return {
      fieldErrors: {},
      form: {
        FirstName1: null,
        LastName1: null,
        FirstName2: null,
        LastName2: null,
        Company: null,
        Email: null,
        Email_key3: null,
        HomeTel: null,
        ContactNo: null,
        MobileTel: null,
        Street1: null,
        Street2: null,
        Zip: null,
        State: null,
        City: null,
        Country: null,
      },
      currentowner: null,
      origForm:null,
      frmDirty:false
    };
  },
  watch: {
    // call again the method if the route changes
    //'$route': 'fetchData'
    "$route.params.owner": {
      handler: function(owner) {
        console.log(owner);
        if (owner ) {
          this.currentowner = owner;
          console.log("currentowner:", this.currentowner);
          this.form.FirstName1 = owner.FirstName1;
          this.form.LastName1 = owner.LastName1;
          this.form.FirstName2 = owner.FirstName2;
          this.form.LastName2 = owner.LastName2;
          this.form.Company = owner.Company;
          this.form.Email = owner.Email;
          this.form.Email_key3 = owner.Email_key3;
          this.form.HomeTel = owner.HomeTel;
          this.form.ContactNo = owner.ContactNo;
          this.form.MobileTel = owner.MobileTel;
          this.form.Street1 = owner.Street1;
          this.form.City = owner.City;
          this.form.Zip = owner.Zip;
          this.form.State = owner.State;
          this.form.Country = owner.Country;
          this.origForm={...this.form}
          this.frmDirty=false
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateDB() {
      let post={
        database:this.currentowner.db,
        id:this.currentowner.id_contacts,
        data: {
          LastName1: this.form.LastName1,
          FirstName1: this.form.FirstName1,
          LastName2: this.form.LastName2,
          FirstName2: this.form.FirstName2,
          Company: this.form.Company,
          Email: this.form.Email,
          Key3: this.form.Email_key3,
          Telephone1: this.form.ContactNo,
          Telephone3: this.form.MobileTel,
          Telephone4: this.form.HomeTel,
          Street1:this.form.Street1,
          Zip:this.form.Zip,
          State:this.form.State,
          City: this.form.City,
          Country: this.form.Country,
          UserWhoUpdated: "T2P"
        }
      }
      console.log("post data",post)
       const headers = {
              'Content-Type': 'application/json' 
          };
      let that=this
      axios
        .put('https://us-central1-terratonepull-c669d.cloudfunctions.net/' + 'contacts/',post,{headers:headers})
        .then((result)=>{
          console.log(result);
          console.log(result.data);
          console.log(result.status);
          console.log(result.statusText);
          if (result.status==200) {
            // make current form as origForm as change is made to database
            this.origForm={...this.form}
            this.frmDirty=false
            that.$ons.notification.toast(
            "Update is done.",{timeout: 2000}
            );
          }
          else 
          { that.$ons.notification.alert(
            "Update is not successful."); 
            }
        },(error)=>{
          console.log("error:",error)
          that.$ons.notification.alert(
            "Update is not successful."); 
        })
       
    },
    isEqual(value, other) {
      // Get the value type
      var type = Object.prototype.toString.call(value);

      // If the two objects are not the same type, return false
      if (type !== Object.prototype.toString.call(other)) return false;

      // If items are not an object or array, return false
      if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

      // Compare the length of the length of the two items
      var valueLen =
        type === "[object Array]" ? value.length : Object.keys(value).length;
      var otherLen =
        type === "[object Array]" ? other.length : Object.keys(other).length;
      if (valueLen !== otherLen) return false;

      // Compare two items
      var compare = function(item1, item2) {
        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
          if (!isEqual(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {
          // If the two items are not the same type, return false
          if (itemType !== Object.prototype.toString.call(item2)) return false;

          // Else if it's a function, convert to a string and compare
          // Otherwise, just compare
          if (itemType === "[object Function]") {
            if (item1.toString() !== item2.toString()) return false;
          } else {
            if (item1 !== item2) return false;
          }
        }
      };

      // Compare properties
      if (type === "[object Array]") {
        for (var i = 0; i < valueLen; i++) {
          if (compare(value[i], other[i]) === false) return false;
        }
      } else {
        for (var key in value) {
          if (value.hasOwnProperty(key)) {
            if (compare(value[key], other[key]) === false) return false;
          }
        }
      }

      // If nothing failed, return true
      return true;
    },
    goBack() {
      let that=this
      if (this.frmDirty){
        this.$ons.notification.confirm(
          {
          message:'Do you want to save the changes before leaving this page?'      ,
          title: 'Contact Update Alert'  ,
          buttonLabels: ['No','Yes'],
          callback: function (answer){
            console.log('answer',answer)
            if (answer==1) {
              that.updateDB()
              that.$router.go(-1);
            } else {
              console.log("leave with no change")
              this.frmDirty=false
              that.$router.go(-1);
            }
          }
          } 
      )
      } else {this.$router.go(-1);}
      
    },
    handleSubmit() {
      let that = this;
      let isFN1Blank = false;
      let isCMYBlank = false;
      console.log("the form:", this.form);
      if (this.form.Company) {
        if (this.form.Company.trim() === "") {
          console.log("Company is blank");
          isCMYBlank = true;
        }
      }
      if (this.form.FirstName1) {
        if (this.form.FirstName1.trim() === "") {
          console.log("Firstname 1 is blank");
          isFN1Blank = true;
        }
      }
      if (
        (!this.form.Company || isCMYBlank) &&
        (!this.form.FirstName1 || isFN1Blank)
      ) {
        that.$ons.notification.alert(
          "Either Firstname or Company cannot be empty!"
        );
      }

      if (this.frmDirty) {
          // Role permission check is needed
          // API Update database

          this.updateDB()
      }
    },

    handleChange(evt) {
      console.log("handleChange :: ", evt.target.name);
      this.fieldErrors = {
        ...this.fieldErrors,
        [evt.target.name]: "",
      };
      console.log("origForm",this.origForm)
      console.log("form is dirty:",! this.isEqual(this.origForm,this.form))
      this.frmDirty=! this.isEqual(this.origForm,this.form)
    },
    handleInvalid(evt) {
      console.log("handleInvalid :: ", evt.target.name);
      this.fieldErrors = {
        ...this.fieldErrors,
        [evt.target.name]: evt.target.validationMessage,
      };
      console.log("error msg::", evt.target.validationMessage);
    },
    errorClass(field) {
      return this.fieldErrors[field] ? "is-danger" : "";
    },
  },
};
</script>
<style scoped>
.edithalfrow {
  width: 45%;
}
.editrow {
  width: 96%;
}
.editmr {
  width: 20%;
}
.editoption {
  width: 80%;
}
.fulldiv {
  width: 100%;
}
</style>
