<template>
  <div id="portfoliotv" class="responsive-table">
    <div style="margin-left:0px" class="responsive-table">
      <vue-ads-table-tree
        :columns="columns"
        :rows="rows"
        :filter="filter"
        :page="page"
        @filter-change="filterChanged"
        @page-change="pageChanged"
      >
        <template slot="firstName" slot-scope="props">
          <a
            :href="
              `https://www.google.com/search?q=${props.row.firstName}+${props.row.lastName}`
            "
            target="_blank"
          >
            {{ props.row.firstName }}
          </a>
        </template>
      </vue-ads-table-tree>
    </div>
  </div>
</template>
<script>
import '/node_modules/vue-ads-table-tree/dist/vue-ads-table-tree.css'
import VueAdsTableTree from 'vue-ads-table-tree';

// Import the EventBus we just created.
import EventBus from '../config/event-bus';

export default {
  name: "BasicTableApp",
  
  components: {
    VueAdsTableTree,
  },
 created(){
        EventBus.$on('portfolio',(data)=>{
          console.log('portfolio event',data)
          this.rows=data
        })
 },
  data: function() {
    return {
      page: 0,
      filter: "",
      columns: [
        {
          property: "LocationOriginal",
          title: "Address",
          direction: null,
          filterable: true,
        },
        {
          property: "CurrAssessTotal",
          title: "Asst Value",
          direction: null,
          filterable: true,
          collapseIcon: true,
        },
                {
          property: "LO",
          title: "LO",
          direction: null,
          filterable: true,
          collapseIcon: true,
        },
      ],
rows :
[
    {
        "LocationOriginal": "319 Knox St",
        "CurrAssessTotal": 10260000,
        "LO": "319 KNOX HOLDINGS LTD."
    },
    {
        "LocationOriginal": "4580 Sidney",
        "CurrAssessTotal": 4547900,
        "LO": "JOHN & ALLAN ENTERPRISES LTD."
    },
    {
        "LocationOriginal": "7340 19th Av",
        "CurrAssessTotal": 10379000,
        "LO": "WONG, JOHN"
    }
],

    };
  },

  methods: {
    filterChanged(filter) {
      this.filter = filter;
    },

    pageChanged(page) {
      this.page = page;
    },

  },
};
</script>

