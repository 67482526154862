<template id="propertyinfo">
  <v-ons-page id="propertyinfoPage">
    <v-ons-card>
      <div v-if="hasData">
        <div class="card__content">
          <v-ons-card style="background-color:black;color:white;">
            <v-ons-list style="background-color:black;color:white;">
              <!--
              <div class="left">
                <img
                  class="list-item__thumbnail"
                  :src="AddressURL"
                  alt="Street View is not available."
                />
              </div>
              -->
              <div
                class="left"
                @click="actionSheetVisible = true"
                style="background-color:black;color:white;"
              >
                <div>{{ Address }}</div>
                <div>{{ Zoning }}</div>
                <div>{{ LegalOwner }}</div>
              </div>
              <!--@click="showStreetView = true"-->
              <div style="margin-top:5px">
                <i
                  class="fas fa-street-view"
                  style="font-size:20px; padding-left:0px;"
                  
                  @click="showStreetView = true"
                ></i>
              </div>
            </v-ons-list>
          </v-ons-card>
          <v-ons-list-item expandable :expanded.sync="isExpanded"
            >More Details...
            <div class="expandable-content" >
              <v-ons-row>
                <v-ons-col>Lot Size: </v-ons-col>
                <v-ons-col>{{ LotSize }}</v-ons-col>
              </v-ons-row>
              <div v-show="BuildingStoreys != ''">
                <v-ons-row>
                  <v-ons-col>Building Storeys: </v-ons-col>
                  <v-ons-col>{{ BuildingStoreys }}</v-ons-col>
                </v-ons-row>
              </div>
              <div v-show="NoOfUnits != ''">
                <v-ons-row>
                  <v-ons-col>No Of Units: </v-ons-col>
                  <v-ons-col>{{ NoOfUnits }}</v-ons-col>
                </v-ons-row>
              </div>
              <v-ons-row>
                <v-ons-col>Gross Tax: </v-ons-col>
                <v-ons-col>{{ GrossTax }}</v-ons-col>
              </v-ons-row>
              <v-ons-row>
                <v-ons-col>Assessment (year): </v-ons-col>
                <v-ons-col
                  >{{ AssessmentTotalValue }} ({{ AssessmentYear }})</v-ons-col
                >
              </v-ons-row>
              <v-ons-row>
                <v-ons-col>Land:</v-ons-col>
                <v-ons-col>{{ AssessmentLand }}</v-ons-col>
              </v-ons-row>
              <v-ons-row>
                <v-ons-col>Buildings:</v-ons-col>
                <v-ons-col>{{ AssessmentBuilding }}</v-ons-col>
              </v-ons-row>
            </div>
          </v-ons-list-item>
        </div>
      </div>
      <div
        v-else
        style="text-align: center; font-size: 20px; margin-top: 20px; "
      >
        Property information is coming...
      </div>
    </v-ons-card>
    <v-ons-card v-show="hasData">
      <div class="card__content">
        <v-ons-list-item tappable @click="go2propPosts">
         Tagged posts
        </v-ons-list-item>
      </div>
    </v-ons-card>
    <v-ons-card v-show="hasHistory">
      <div class="card__content">
        <v-ons-list-item expandable
          >history...
          <div class="expandable-content"></div>
        </v-ons-list-item>
      </div>
    </v-ons-card>
    <v-ons-card v-show="hasNotes">
      <div class="card__content">
        <v-ons-list-item expandable
          >Notes...
          <div class="expandable-content"></div>
        </v-ons-list-item>
      </div>
    </v-ons-card>
    <v-ons-action-sheet :visible.sync="actionSheetVisible" cancelable>
      <v-ons-action-sheet-button icon="md-search" @click="goToSearch2TL"
        >Search title</v-ons-action-sheet-button
      >
      <v-ons-action-sheet-button
        icon="md-search"
        v-show="this.LegalOwner"
        @click="goToSearch2CY"
        >Search {{ LegalOwner }}</v-ons-action-sheet-button
      >
    </v-ons-action-sheet>
    <v-ons-dialog cancelable :visible.sync="showStreetView">
      <img :src="AddressURL" alt="Street View is not available." />
    </v-ons-dialog>
  </v-ons-page>
</template>

<script>
// Import the EventBus we just created.
import EventBus from '../config/event-bus';
import router from "../router"
import state from  '../state'
export default {
  data: function() {
    return {
      PID: "",
      Address: "",
      Zoning: "",
      LotSize: 0,
      BuildingStoreys: 0,
      NoOfUnits: 0,
      GrossTax: 0,
      AssessmentTotalValue: 0,
      AssessmentYear: "",
      AssessmentLand: 0,
      AssessmentBuilding: 0,
      LegalOwner: "",
      hasData: false,
      hasNotes:false,
      hasHistory:false,
      hasPosts:true,
      isExpanded:false,
      AddressURL: "",
      dialogVisible: false,
      actionSheetVisible: false,
      showStreetView: false,
      lonlat:null
    };
  },
  created() {
    EventBus.$on("get_coordinate",(data) => {
      console.log("Get lonlat event in propertyinfo")
      this.lonlat=data
    }),
    EventBus.$on("get_property", (data) => {
      console.log("Get property event in propertyinfo: ");
      if (data !== null) {
        this.PID = data.Pid;
        this.Address = data.CivicAddress;
        this.Zoning = data.Zoning;
        this.LotSize = data.LotSize;
        this.BuildingStoreys = data.BuildingStoreys;
        this.NoOfUnits = data.NoOfUnit;
        this.GrossTax = data.GrossTax.replace(/.(?=(?:.{3})+$)/g, "$&,");
        this.AssessmentTotalValue = data.AssessmentValue.replace(
          /.(?=(?:.{3})+$)/g,
          "$&,"
        );
        this.AssessmentYear = data.AssessmentYear;
        this.AssessmentLand = data.CurrAssessLand.replace(
          /.(?=(?:.{3})+$)/g,
          "$&,"
        );
        this.AssessmentBuilding = data.CurrAssessBuilding.replace(
          /.(?=(?:.{3})+$)/g,
          "$&,"
        );
        this.LegalOwner = data.LO;
        this.hasData = true;
        this.AddressURL =
          "https://maps.googleapis.com/maps/api/streetview?size=360x300&fov=120&pitch=10&source=outdoor&location=" +
          data.Address +
          "&key=AIzaSyDEtklVqe60jt7Z6NpX6DrBbu-f2UKePGs";
      } else this.hasData = false;
    });
  }, // end created
  computed: {
    hasPropInfo() {
      return this.hasData;
    },
  },
  methods: {
    go2propPosts(){
      
      let senddata={'pid':this.PID,'address':this.Address}
      EventBus.$emit("propPosts",senddata)
      document.querySelector("ons-tabbar").setActiveTab(4);
      
    },
    showStreetviewPage () {
      state.lonlat=this.lonlat
      console.log("lonlat in propertyinfo",this.lonlat)
      router.push('streetview')
    },
    goToSearch2TL() {
      this.actionSheetVisible = false;
      console.log("Pid", this.PID);
      EventBus.$emit("SearchFrmPInfo", this.PID);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
    goToSearch2CY() {
      this.actionSheetVisible = false;
      console.log("LO", this.LegalOwner);
      EventBus.$emit("SearchFrmPInfo", this.LegalOwner);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
  },
};
</script>
<style scoped>
.list-item__thumbnail {
  border-radius: 0%;
  width: 80%;
  height: 80%;
  cursor: pointer;
  margin: 0%;
}
img:hover {
  -webkit-transform: scale(5);
  transform: scale(5);
}
img {
  border: 1px solid #021a40;
}
</style>
