<template>
  <v-ons-page>
    <v-ons-list>
      <v-ons-list-item>
  <div>
    <span v-html="htmlData"></span>
  </div>
      </v-ons-list-item>
    </v-ons-list>
    <textarea id="note" v-model="markdown"></textarea>
    <v-ons-button @click="convert">Post</v-ons-button>
  </v-ons-page>
</template>

<script>
import showdown from "showdown"
export default {

  data () {
    return {
      markdown: '',
      htmlData: ''
    }
  },
  methods:{
    convert(){
      let converter=new showdown.Converter({emoji: true,encodeEmails:true,openLinksInNewWindow:true,underline:true,simpleLineBreaks:true})
      console.log("htmlData",converter.makeHtml(this.markdown))
      this.htmlData=converter.makeHtml(this.markdown)
    }
  }
}
</script>
<style scoped>
textarea#note {
	width:100%;
	display:block;
	max-width:100%;
	line-height:1.5;
	padding:15px 15px 30px;
	border-radius:3px;
	border:1px solid #F7E98D;
	font:13px Tahoma, cursive;
	transition:box-shadow 0.5s ease;
	box-shadow:0 4px 6px rgba(0,0,0,0.1);
	background:linear-gradient(#F9EFAF, #F7E98D);
	background:-o-linear-gradient(#F9EFAF, #F7E98D);
	background:-ms-linear-gradient(#F9EFAF, #F7E98D);
	background:-moz-linear-gradient(#F9EFAF, #F7E98D);
	background:-webkit-linear-gradient(#F9EFAF, #F7E98D);
}
</style>