<template id="search">
  <v-ons-page id="searchpage">
    <v-ons-toolbar style="margin-bottom:0px;" >
      <div class="center" style="margin-top:7px;">
        <input :disabled="true"
          type="search"
          id="txtCoorpListSearch"
          autocomplete="off"
          v-model="strSearch"
          @keyup="GetSearchLists"
          placeholder="Search Contacts, Companies(min 3 letters) or Titles(PID)"
        />
      </div>
    </v-ons-toolbar>
    <v-ons-dialog cancelable :visible.sync="showDialog">
      <PDFJSViewer :path="`${path}`" :name="`${name}`" />
    </v-ons-dialog>
    <v-ons-card>
      <div>
        <v-ons-row>
          <v-ons-col>
            <label class="checkbox">
              <input
                type="checkbox"
                data-action="contactsCard"
                class="checkbox__input"
                checked="checked"
                @change="toggleDisplay"
              />
              <div class="checkbox__checkmark"></div>
              Contacts
            </label>
          </v-ons-col>
          <v-ons-col>
            <label class="checkbox">
              <input
                type="checkbox"
                data-action="companiesCard"
                class="checkbox__input"
                checked="checked"
                @change="toggleDisplay"
              />
              <div class="checkbox__checkmark"></div>
              Companies
            </label>
          </v-ons-col>
          <v-ons-col>
            <label class="checkbox">
              <input
                type="checkbox"
                data-action="titlesCard"
                class="checkbox__input"
                checked="checked"
                @change="toggleDisplay"
              />
              <div class="checkbox__checkmark"></div>
              Titles
            </label>
          </v-ons-col>
        </v-ons-row>
      </div>
    </v-ons-card>
    <v-ons-card v-show="showContacts" id="contactsCard">
      <!-- use contactlist2() -->
      <div class="title">Contacts:</div>
      <div class="content">
        <v-ons-list id="list">
          <v-ons-lazy-repeat :render-item="renderItem4" :length="items4.length">
          </v-ons-lazy-repeat>
        </v-ons-list>
      </div>
    </v-ons-card>
    <!-- 
    <v-ons-card>
      <div class="title">Contacts:</div>
      <div class="content">
        <v-ons-list>
          <v-ons-lazy-repeat :render-item="renderItem3" :length="items3.length">
          </v-ons-lazy-repeat>
        </v-ons-list>
      </div>
    </v-ons-card>
    -->
    <v-ons-card id="companiesCard" v-show="showCompanies">
      <div class="title">Companies:</div>
      <div class="content">
        <v-ons-list>
          <v-ons-lazy-repeat :render-item="renderItem2" :length="items2.length">
          </v-ons-lazy-repeat>
        </v-ons-list>
      </div>
    </v-ons-card>
    <v-ons-card id="titlesCard" v-show="showTitles">
      <div class="title">Titles:</div>
      <div class="content">
        <v-ons-list>
          <v-ons-lazy-repeat :render-item="renderItem1" :length="items1.length">
          </v-ons-lazy-repeat>
        </v-ons-list>
      </div>
    </v-ons-card>
    <v-ons-fab position="bottom right" @click.native="goToTop()">
      <v-ons-icon icon="ion-ios-arrow-up"></v-ons-icon>
    </v-ons-fab>
  </v-ons-page>
</template>
<script>
//import storage  from "../firebase"

import fb from "../firebase";
import Vue from "vue/dist/vue.js";
import axios from "axios";
import PDFJSViewer from "../components/PDFJSViewer";

// Import the EventBus we just created.
import EventBus from '../config/event-bus';
const proxyurl = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)

export default {
  components: {
    PDFJSViewer,
  },
  /*watch: {
    $route(to, from) {
      console.log("route switched");
      console.log(from);
      console.log(to.params.strSearchFromOwnerInfo);
      if (to.params.strSearchFromOwnerInfo) {
      this.strSearchFromOwnerInfo = to.params.strSearchFromOwnerInfo;

      this.GetSearchListsFromOwnerInfo();
      }
    },
  },*/
  beforeRouteLeave(to, from, next) {
    console.log("Stop route");
    //const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
    next();
  },
  beforeRouteEnter(to, from, next) {
    console.log("start route");
    console.log(to);
    next();
  },
  mounted() {
    EventBus.$on("get_url", (data) => {
      this.path = "lib/web/viewer.html";
      this.showDialog = true;
      this.name = encodeURIComponent(data);
      //this.name="https://firebasestorage.googleapis.com%2Fv0%2Fb%2Fterratonepull.appspot.com%2Fo%2Fcorporatesearch%252FABC.D%2520TURF%2520LTD.%2520%282017%29.pdf%3Falt%3Dmedia%26token%3Dbdba0ddd-1a8d-44eb-8ff3-473641ee0ea1"
    }),
      EventBus.$on("SearchFrmOInfo", (data) => {
        console.log("get SearchFrmOInfo", data);
        this.strSearchFromOwnerInfo = data;
        this.GetSearchListsFromOwnerInfo();
      }),
      EventBus.$on("SearchFrmPInfo", (data) => {
        console.log("get SearchFrmPInfo", data);
        this.strSearchFromOwnerInfo = data;
        this.GetSearchListsFromOwnerInfo();
      });
  },

  data: function() {
    return {
      strSearch: "",
      items1: [],
      items2: [],
      items3: [],
      items4: [],
      // for using pdf.js pre-build web viewer
      name: "Test_PDF.pdf", //change which pdf file loads
      path: "lib/web/viewer.html", //path of the PDF.js viewer.html
      //

      showDialog: false,
      showContacts:true,
      showCompanies:true,
      showTitles:true,
    };
  },
  /*
  created() {
    this.items = [...new Array(100)].map((_, i) => {
      return { name: `item${i + 1}` }
    })
  }, */
  methods: {
    goBack() {
      console.log("router", this.$router);
      this.$router.go(-1);
    },
    toggleDisplay: function(e) {
      var x = null;
      switch (e.target.dataset.action) {
        case "contactsCard":
          this.showContacts=!this.showContacts
          /*
          x = document.getElementById("contactsCard");
          if (x.style.display === "none") {
            x.style.display = "block";
          } else {
            x.style.display = "none";
          }
          */
          break;
        case "companiesCard":
          this.showCompanies=!this.showCompanies
          /*
          x = document.getElementById("companiesCard");
          if (x.style.display === "none") {
            x.style.display = "block";
          } else {
            x.style.display = "none";
          }
          */
          break;
        case "titlesCard":
          this.showTitles=!this.showTitles
          /*
          x = document.getElementById("titlesCard");
          if (x.style.display === "none") {
            x.style.display = "block";
          } else {
            x.style.display = "none";
          }
          */
          break;
        default:
          console.log("Nothing to hide");
      }
    },
    scrollFunction: function(e) {
      console.log(e.target.scrollTop);
    },
    ScrollToContacts() {
      document.querySelector("ons-card#contactsCard").scrollTop = 0;
    },
    ScrollToCompanies() {
      document.querySelector("ons-card#companiesCard").scrollTop = 0;
    },
    ScrollToTitles() {
      document.querySelector("ons-card#titlesCard").scrollTop = 0;
    },
    GetSearchListsFromOwnerInfo() {
      console.log("call GetSearchListFromOwnerInfo");
      console.log(this.strSearchFromOwnerInfo);
      if (this.strSearchFromOwnerInfo.length > 0) {
        this.strSearch = this.strSearchFromOwnerInfo;
        this.GetContactList2();
        //this.GetContactList();
        this.GetTitleSearchList();
        this.GetCompSearchList();
      }
    },
    GetSearchLists() {
      console.log("call GetSearchList");
      this.GetContactList2();
      //this.GetContactList();
      this.GetTitleSearchList();
      this.GetCompSearchList();
    },
    GetContactList2() {
      if (this.strSearch.length > 2) {
        //this.strSearch = this.strSearch.replace(/ /g, "-");
        //this.strSearch = this.strSearch + "!BC"
        console.log("SearchString:", this.strSearch);
        if (this.strSearch) {
          var contactArray = [];
          const search_criteria = "&criteria=name,company";
           //const search_criteria="&criteria=name,company,telephone,contact-address,property-address,city,zip"
          //https://hv3ziiajak.execute-api.us-west-2.amazonaws.com/Beta/contact/Amy C
          var dbURL =
            "https://us-central1-terratonepull-c669d.cloudfunctions.net/contacts/linkproperty/search?text=" +
            this.strSearch +
            search_criteria;
          console.log("URL:", dbURL);
          var contactlist = [];

          axios.get(dbURL).then(function(response) {
            console.log("response:", response);
            if (response.status == 200) {
              //console.log(response.data.body);
              contactlist = response.data;
              //console.log("contactlist:",typeof(contactlist))
              contactlist.forEach(function(contact) {
                contactArray.push(contact);
              });
            }
          });

          this.items4 = contactArray;
          console.log(contactArray);
        }
      }
    },
    GetContactList() {
      if (this.strSearch.length > 2) {
        this.strSearch = this.strSearch.replace(/ /g, "-");
        //this.strSearch = this.strSearch + "!BC"
        console.log("SearchString:", this.strSearch);
        if (this.strSearch) {
          var contactArray = [];
          //https://hv3ziiajak.execute-api.us-west-2.amazonaws.com/Beta/contact/Amy C
          var dbURL =
            "https://hv3ziiajak.execute-api.us-west-2.amazonaws.com/Beta/contact/" +
            this.strSearch;
          console.log("URL:", proxyurl + dbURL);
          var contactlist = [];

          axios.get(proxyurl + dbURL).then(function(response) {
            //console.log("response:", response);
            if (response.data.statusCode == 200) {
              //console.log(response.data.body);
              contactlist = response.data.body;
              //console.log("contactlist:",typeof(contactlist))
              contactlist.forEach(function(contact) {
                contactArray.push(contact);
              });
            }
          });

          this.items3 = contactArray;
          //console.log(contactArray)
        }
      }
    },
    GetTitleSearchList() {
      // Create a storage reference from our storage service
      if (this.strSearch.length > 2) {
        this.strSearch = this.strSearch.toUpperCase();
        console.log(this.strSearch);
        var strlength = this.strSearch.length;
        var strFrontCode = this.strSearch.slice(0, strlength - 1);
        var strEndCode = this.strSearch.slice(
          strlength - 1,
          this.strSearch.length
        );

        var startcode = this.strSearch;
        var endcode =
          strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
        var titleArray = [];
        fb.titlesearchRef
          .orderBy("filename")
          .where("filename", ">=", startcode)
          .where("filename", "<", endcode)
          .limit(10)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              titleArray.push(
                doc.data().filename.substr(0, doc.data().filename.length - 4)
              );
            });
          })
          .catch(function(error) {
            console.log("Error getting documents: ", error);
          });
        this.items1 = titleArray;
      } // end if (this.strSearch.length > 2)
    }, // end of function GetCompSearchList

    GetCompSearchList() {
      // Create a storage reference from our storage service
      if (this.strSearch.length > 2) {
        this.strSearch = this.strSearch.toUpperCase();
        console.log(this.strSearch);
        var strlength = this.strSearch.length;
        var strFrontCode = this.strSearch.slice(0, strlength - 1);
        var strEndCode = this.strSearch.slice(
          strlength - 1,
          this.strSearch.length
        );

        var startcode = this.strSearch;
        var endcode =
          strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
        var companyArray = [];
        fb.corpsearchRef
          .orderBy("filename")
          .where("filename", ">=", startcode)
          .where("filename", "<", endcode)
          .limit(10)
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              companyArray.push(
                doc.data().filename.substr(0, doc.data().filename.length - 4)
              );
            });
          })
          .catch(function(error) {
            console.log("Error getting documents: ", error);
          });
        this.items2 = companyArray;
      } // end if (this.strSearch.length > 2)
    }, // end of function GetCompSearchList

    renderItem1(index) {
      return new Vue({
        //template: `<v-ons-list-item>${ this.items[index].name }</v-ons-list-item>`
        template: `<li class="list-item" @click="ClickMe"><div class="list-item__center">${this.items1[index]}</div></li>`,
        methods: {
          ClickMe(event) {
            console.log("Clicked:", index, event.target.innerHTML);
            var aPID = event.target.innerHTML;
            const pdf_name_prefix = "titlesearch/";
            var pdf_name = pdf_name_prefix + aPID + ".pdf";
            console.log(" pdf_name: " + pdf_name);
            var starsRef = fb.storage2.ref().child(pdf_name);
            // Get the download URL
            starsRef.getDownloadURL().then(function(url) {
              console.log("pdf URL: " + url);

              EventBus.$emit("get_url", url);
              //var pdfurl = './TITLE-CA6771686-PID-004-101-235.pdf';
            });
          },
        },
      });
    },
    renderItem2(index) {
      return new Vue({
        //template: `<v-ons-list-item>${ this.items[index].name }</v-ons-list-item>`
        template: `<li class="list-item "  @click="ClickMe"><div class="list-item__center">${this.items2[index]}</div></li>`,
        methods: {
          ClickMe(event) {
            console.log("Clicked:", index, event.target.innerHTML);
            var aCompany = event.target.innerHTML;
            const pdf_name_prefix = "corporatesearch/";
            var pdf_name = pdf_name_prefix + aCompany + ".pdf";
            console.log(" pdf_name: " + pdf_name);
            var starsRef = fb.storage2.ref().child(pdf_name);
            // Get the download URL
            starsRef.getDownloadURL().then(function(url) {
              console.log("pdf URL: " + url);

              EventBus.$emit("get_url", url);
              //var pdfurl = './TITLE-CA6771686-PID-004-101-235.pdf';
            });
          },
        },
      });
    },
    renderItem3(index) {
      if (!this.items3.length > 0) {
        return new Vue({
          template: `<div>Searching...</div>`,
        });
      }
      //console.log(this.items3[0])
      var lastname1 = "";
      var firstname1 = "";
      var company = "";
      var propertyaddress = "";
      var workphone = "";
      var homephone = "";
      var mobilephone = "";
      var email = "";
      //console.log(index,":",this.items3[index].LastName1)
      lastname1 = this.items3[index].LastName1 ?? "";
      firstname1 = this.items3[index].FirstName1 ?? "";
      company = this.items3[index].Company ?? "";
      propertyaddress = this.items3[index].LocationOriginal ?? "";
      workphone = this.items3[index].WorkTel ?? "";
      homephone = this.items3[index].HomeTel ?? "";
      mobilephone = this.items3[index].MobileTel ?? "";
      email = this.items3[index].Email2 ?? "";

      return new Vue({
        //template: `<v-ons-list-item>${ this.items[index].name }</v-ons-list-item>`

        template: `<li class="list-item "><div class="list-item__center" style="display:block">
      <div class="list-item__title">${firstname1 + " " + lastname1}</div>
      <div class="list-item__title">${company}</div>
      
      <div calss="list-item__title">${workphone}</div>
      <div calss="list-item__title">${homephone}</div>
      <div calss="list-item__title">${mobilephone}</div>
      <div calss="list-item__title">${email}</div>
      <div class="list-item__subtitle">Property: ${propertyaddress}</div>
      </div></li>`,
      });
    },

    renderItem4(index) {
      if (!this.items4.length > 0) {
        return new Vue({
          template: `<div>Searching...</div>`,
        });
      }
      //console.log(this.items3[0])
      var lastname1 = "";
      var firstname1 = "";
      var company = "";
      var propertyaddress = "";
      var workphone = "";
      var homephone = "";
      var mobilephone = "";
      var email = "";
      var NoOfProp = "";
      var propertylist = [];
      var strPropertyList = "";
      //console.log(index,":",this.items3[index].LastName1)
      lastname1 = this.items4[index].LastName1 ?? "";
      firstname1 = this.items4[index].FirstName1 ?? "";
      company = this.items4[index].Company ?? "";

      // propertyaddress = this.items3[index].LocationOriginal ?? "";
      workphone = this.items4[index].Telephone1 ?? "";
      if (!workphone.length == 0) {
        workphone = "Work: " + workphone;
      }

      homephone = this.items4[index].Telephone4 ?? "";
      if (!homephone.length == 0) {
        homephone = "Home: " + homephone;
      }
      mobilephone = this.items4[index].Telephone3 ?? "";
      if (!mobilephone.length == 0) {
        mobilephone = "Mobile: " + workphone;
      }
      email = this.items4[index].Email ?? "";
      if (!email.length == 0) {
        email = "Email: " + email;
      }
      propertylist = this.items4[index].PropertyList;
      propertylist.forEach(function(prop) {
        strPropertyList =
          strPropertyList +
          "<li style='list-style:square;color:blue' >" +
          prop.LocationOriginal +
          "</li>";
      });
      NoOfProp = this.items4[index].PropertyList.length ?? "";
      return new Vue({
        //template: `<v-ons-list-item>${ this.items[index].name }</v-ons-list-item>`

        template: `<li class="list-item"><div class="list-item__center" style="display:block">
      <div class="list-item__title" >${firstname1 + " " + lastname1}</div>
      <div class="list-item__title" >${company}</div>
      
      <div calss="list-item__title">${workphone}</div>
      <div calss="list-item__title">${homephone}</div>
      <div calss="list-item__title">${mobilephone}</div>
      <div calss="list-item__title">${email}</div>
      <div style='color:brown'>Properties(${NoOfProp}):</div>
      ${strPropertyList}
       </div></li>`,
        data() {
          return {
            index: index,
          };
        },
        computed: {
          itemName() {
            return this.index < self.selectedItems.length
              ? self.selectedItems[this.index]
              : "";
          },
        },
        methods: {
          itemSelected(itemName) {
            this.$ons.notification.alert("item selected: " + itemName);
          },
        },
      });
    },
    goToTop() {
      console.log("goToTop");
      document.querySelector("ons-page#searchpage").scrollTop = 0;
    },
    GoToMap() {
      console.log("goToMap");
    },
  },
};
</script>
<style scoped>
#txtCoorpListSearch {
  background-image: url("../assets/img/searchicon.jpg");
  background-size: 20px;
  background-position: 4px 10px;
  background-repeat: no-repeat;
  width: 97%;
  padding: 0px 5px 0px 30px;
  font-size: 16px;
  border: 1px solid #ddd;
  margin-bottom: 0px;
  margin-left: 5px;
  display: block;
  line-height: 40px;
}

#txtCoorpListSearch::placeholder {
  font-size: 12px;
}

.button-bar__button {
  background-color: royalblue;
  color: white;
}
</style>
