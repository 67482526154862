<template>
  <v-ons-page id="bookmarkPage">
    <v-ons-toolbar style="background: #E59866;">
      <div class="left">
        <v-ons-toolbar-button style="color: white"  @click="goBack()" ><i class="fa fa-arrow-left"></i></v-ons-toolbar-button>
      </div>
      <div class="center" style="color: white">Bookmarks</div>
    </v-ons-toolbar>
  <div >
    <div v-if="bookmarks.length">
      <div class="content" style="height: 85%">
        <div v-for="bookmark in bookmarks" class="post" :key="bookmark.id">
          <v-ons-card>
            <v-ons-row>
              <v-ons-col width="85%">
            <div @click="go2Map(bookmark)" style="color: blue">{{ bookmark.data.address }}</div>
              </v-ons-col>
              <v-ons-col>
                <span style="float: right; font-style: italic">
                        <button
                          class="toolbar-button"
                          id="btnDelete"
                          @click="deleteBookmark(bookmark.id)"
                        >
                          <i
                            class="far fa-trash-alt"
                            style="color:grey-alt;font-size:20px; padding-left:0px;"
                          ></i>
                        </button>
                </span>
              </v-ons-col>
              </v-ons-row>
            </v-ons-card>
          </div>
        </div>
      </div>
    <div v-else>
      <p class="no-results">There are currently no bookmarks</p>
    </div>
  </div>
  </v-ons-page>
</template>
<script>


import fb from "../firebase";
import { mapState } from "vuex";
// Import the EventBus we just created.
//import { EventBus } from "../main";
import EventBus from '../config/event-bus';

const timeoutDuration = 2000;

export default {
 data(){
   return {
   }
 },
 methods:{
    go2Map(abookmark){
      EventBus.$emit("go2map", abookmark.data.centre);
      this.goBack()
    },
    goBack() {
        this.$router.go(-1);
    },
    deleteBookmark(bookmark_id){

      //statusSpan.innerText = 'deleting...';
                console.log("bookmark_id",bookmark_id)
                let uid=this.currentUser.uid
                let that=this
                this.$ons.notification.confirm({
                title: 'DELETE',
                messageHTML: 'Do you really want to delete the bookmark ?',
                buttonLabels: ['No', 'Yes'],
                animation: 'default',
                cancelable: true,
                callback: function(index) {
                    if(index == -1) {
                        console.log('confirm default');
                    } else if(index == 0) {
                        console.log('confirm:No');
                    } else if(index == 1) {
                        console.log('confirm:Yes');
                      
                        that.$ons.notification.toast("deleting...", { timeout: 200, animation: "fall" });
                        console.log("selected bookmarks",that.bookmarks.find(x=>x.id===bookmark_id).data.parcel_id)
                        var selectedBookmark_parcel_id=that.bookmarks.find(x=>x.id===bookmark_id).data.parcel_id
                        fb.usersCollection.doc(uid).collection("bookmarks")

                          .doc(bookmark_id)
                          .delete()
                          .then(() => {
                            //statusSpan.innerText = 'deleted...';
                            that.$ons.notification.toast("deleted...", {
                              timeout: 200,
                              animation: "fall"
                              
                            });
                            EventBus.$emit("delete_bookmark_parcel", selectedBookmark_parcel_id);
                            return;
                          })
                          .catch(error => {
                            console.log(error);
                            //statusSpan.innerText = 'oops... something went wrong!';
                            that.$ons.notification.toast("oops... something went wrong!", {
                              timeout:200,
                              animation: "fall"
                          });
                          });

                          setTimeout(() => {
                            //statusSpan.innerText = 'idle...';
                            that.$ons.notification.toast("idle...", { timeout: 100, animation: "fall" });
                          }, timeoutDuration);
                        
                      }
                  }
                })
            
    },
 },
  computed:{
     ...mapState(["currentUser", "bookmarks"]),     
  },
  mounted() {   
  },
}

</script>
